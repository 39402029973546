(function ($, root, undefined) {
	

	$(function () {
		"use strict";



		// Google analytics
		function googleAnalytics() {
			var AnalyticsScript = document.createElement('script');
			var AnalyticsId = 'GTM-MVTK4J4Z';
			AnalyticsScript.onload = function () {
				window.dataLayer = window.dataLayer || [];
				function gtag() { dataLayer.push(arguments); }
				gtag('js', new Date());
				gtag('config', AnalyticsId);
			};
		
			AnalyticsScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + AnalyticsId;
			document.head.appendChild(AnalyticsScript);
		}
		
		if (getCookie('cookieControlTracking') && getCookie('cookieControlTracking') == 'accept') {
			googleAnalytics();
		}
		
		document.addEventListener('cookieAccept', function () {
			googleAnalytics();
		}, false);
		

		$("body").removeClass("j-animate");

		$(".o-landing__video-button").click(function () {
			weasl({
				name: "landing-video",
				content:
					'<iframe width="560" height="315" src="https://www.youtube.com/embed/' +
					$(this).data("youtube-id") +
					'?&autoplay=1" frameborder="0" allow="autoplay; encrypted-media"  allowfullscreen></iframe>'
			});
		});

		$(".o-mobile-navigation-button").click(function () {
			$(this).toggleClass("active");
			$(".o-primary-navigation")
				.stop()
				.toggleClass("active");
			$("body").toggleClass("j-menu-active");

			if ($(this).hasClass("active")) {
				$(".o-mobile-navigation-button__text").text(
					"Close Mobile Navigation"
				);

				$(".o-primary-navigation a").attr("tab-index", "-1");

				$(".o-primary-navigation a")
					.first()
					.focus();
			} else {
				$(".o-primary-navigation a").removeAttr("tab-index");

				$(".o-mobile-navigation-button__text").text(
					"Open Mobile Navigation"
				);
				$("#content").focus();
			}
		});

		$(".o-primary-navigation a")
			.last()
			.keydown(function () {
				if ($(".o-mobile-navigation-button").hasClass("active")) {
					setTimeout(function () {
						$(".o-mobile-navigation-button").focus();
					}, 0);
				}
			});

		$("[data-fancybox]").fancybox({
			animationEffect: "fade"
		});

		$(".j-lazy-image").each(function () {
			var imgSrcUrl = $(this).data("src");
			var imgSrcSetUrl = $(this).data("srcset");
			$(this).waypoint(
				function () {
					if ($(this.element).attr("data-srcset")) {
						$(this).load(imgSrcSetUrl, function () {
							$(this.element)
								.attr("srcset", imgSrcSetUrl)
								.addClass("loaded");
						});
					}
					if ($(this.element).data("src")) {
						$(this).load(imgSrcUrl, function () {
							$(this.element)
								.attr("src", imgSrcUrl)
								.addClass("loaded");
						});
					}
				},
				{ offset: "90%" }
			);
		});

		$(".j-lazy-bg").each(function () {
			var imgSrcUrl = $(this).data("src");
			var thumbImgSrcUrl = $(this).css("background-image");
			$(this).waypoint(
				function () {
					$(this).load(imgSrcUrl, function () {
						$(this.element)
							.css({
								"background-image":
									"url(" + imgSrcUrl + "), " + thumbImgSrcUrl
							})
							.addClass("loaded");
					});
				},
				{ offset: "90%" }
			);
		});
	});
 

		// init Isotope
		var $container = $("#o-archive-meetings__list");
		console.log("Initializing Isotope...");

		$container.imagesLoaded(function () {
			console.log("Images have loaded. Initializing Isotope now.");
			$container.isotope({
				itemSelector: ".o-meeting",
				layoutMode: "fitRows",
				animationEngine: "best-available",
				masonry: {
					columnWidth: $container.width() / 3
				}
			});
			console.log("Isotope initialized.");
		});

		// filter functions
		var filterFns = {
			// show if number is greater than 50
			numberGreaterThan50: function () {
				var number = $(this).find(".number").text();
				console.log("Filtering for numbers greater than 50. Found number:", number);
				return parseInt(number, 10) > 50;
			},
			// show if name ends with -ium
			ium: function () {
				var name = $(this).find(".name").text();
				console.log("Filtering for names ending with -ium. Found name:", name);
				return name.match(/ium$/);
			}
		};

		// bind filter button click
		$("#filters").on("click", "button", function () {
			var filterValue = $(this).attr("data-filter");
			console.log("Filter button clicked. Filter value:", filterValue);
			
			// use filterFn if matches value
			filterValue = filterFns[filterValue] || filterValue;
			console.log("Using filter value:", filterValue);
			console.log("A TEST!");
			
			$container.isotope({ filter: filterValue });
		});

		// change is-checked class on buttons
		$("#filters").each(function (i, buttonGroup) {
			var $buttonGroup = $(buttonGroup);
			$buttonGroup.on("click", "button", function () {
				console.log("Button clicked in filter group:", $buttonGroup);
				$buttonGroup.find(".is-checked").removeClass("is-checked");
				$(this).addClass("is-checked");
				console.log("Updated button class to is-checked for button:", $(this));
			});
		});



})(jQuery, this);



