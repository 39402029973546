var contains = function (haystack, arr) {
  if (haystack.length) {
    return arr.some(function (v) {
      return haystack.indexOf(v) >= 0;
    });
  }

  return arr;
};

// Vue
if (document.querySelector(".o-archive-events")) {
  new Vue({
    el: ".o-archive-events",
    data: {
      events: {
        data: eventsData,
        taxonomy: {
          sectors: eventSectors,
          months: eventMonths
        }
      },
      sort: {
        by: "title",
        order: "asc",
        options: {
          "A-Z": "asc",
          "Z-A": "desc"
        }
      },
      selected: {
        sector: [],
        month: []
      },
      pagination: {
        currentPage: 1,
        itemsPerPage: 6,
        rangeStart: 0,
        totalItems: eventsData.length
      }
    },
    computed: {
      rangeEnd: function () {
        var number =
          this.pagination.rangeStart + this.pagination.itemsPerPage;
        if (number >= this.totalItems) {
          number = this.totalItems;
        }
        return number;
      },
      pages: function () {
        return Math.ceil(
          this.pagination.totalItems / this.pagination.itemsPerPage
        );
      }
    },
    updated: function () {
      if (
        !this.selected.sector &&
        !this.selected.country &&
        !this.selected.month
      ) {
        this.pagination.totalItems = eventsData.length;
      } else {
        this.pagination.totalItems = this.filteredEvents().length;
      }
    },
    methods: {
      setPage: function (page) {
        this.pagination.currentPage = page;
        if (page == 1) {
          this.pagination.rangeStart = 0;
        } else {
          this.pagination.rangeStart =
            page * this.pagination.itemsPerPage -
            this.pagination.itemsPerPage;
        }
      },
      nextPage: function (event) {
        this.pagination.rangeStart = this.rangeEnd;
        this.pagination.currentPage += 1;

        if (this.pagination.rangeStart >= this.totalItems)
          this.pagination.rangeStart =
            this.rangeEnd - this.pagination.itemsPerPage;

        if (
          this.pagination.currentPage >=
          this.pagination.totalItems / this.pagination.itemsPerPage
        )
          this.pagination.currentPage = Math.ceil(
            this.pagination.totalItems /
            this.pagination.itemsPerPage
          );
      },
      previousPage: function (event) {
        this.pagination.rangeStart =
          this.pagination.rangeStart - this.pagination.itemsPerPage;
        this.pagination.currentPage -= 1;

        if (this.pagination.rangeStart <= 0)
          this.pagination.rangeStart = 0;

        if (this.pagination.currentPage <= 1)
          this.pagination.currentPage = 1;
      },
      filteredEvents: function () {
        return this.events.data
          .filter(event =>
            contains(this.selected.sector, event.sectors)
          )
          .filter(event =>
            contains(this.selected.month, event.month)
          );
      }
    }
  });
}
// Support filtering

if (document.querySelector(".o-archive-support")) {
  new Vue({
    el: ".o-archive-support",
    data: {
      support: {
        data: supportData,
        taxonomy: {
          type: supportType
        }
      },
      sort: {
        by: "title",
        order: "asc",
        options: {
          "A-Z": "asc",
          "Z-A": "desc"
        }
      },
      selected: {
        type: []
      },
      pagination: {
        currentPage: 1,
        itemsPerPage: 6,
        rangeStart: 0,
        totalItems: supportData.length
      }
    },
    computed: {
      rangeEnd: function () {
        var number =
          this.pagination.rangeStart + this.pagination.itemsPerPage;
        if (number >= this.totalItems) {
          number = this.totalItems;
        }
        return number;
      },
      pages: function () {
        return Math.ceil(
          this.pagination.totalItems / this.pagination.itemsPerPage
        );
      }
    },
    updated() {
      if (!this.selected.type && !this.selected.country) {
        this.pagination.totalItems = supportData.length;
      } else {
        this.pagination.totalItems = this.filteredSupport().length;
      }
    },
    methods: {
      setPage: function (page) {
        this.pagination.currentPage = page;
        if (page == 1) {
          this.pagination.rangeStart = 0;
        } else {
          this.pagination.rangeStart =
            page * this.pagination.itemsPerPage -
            this.pagination.itemsPerPage;
        }
      },
      nextPage: function (support) {
        this.pagination.rangeStart = this.rangeEnd;
        this.pagination.currentPage += 1;

        if (this.pagination.rangeStart >= this.totalItems)
          this.pagination.rangeStart =
            this.rangeEnd - this.pagination.itemsPerPage;

        if (
          this.pagination.currentPage >=
          this.pagination.totalItems / this.pagination.itemsPerPage
        )
          this.pagination.currentPage = Math.ceil(
            this.pagination.totalItems /
            this.pagination.itemsPerPage
          );
      },
      previousPage: function (support) {
        this.pagination.rangeStart =
          this.pagination.rangeStart - this.pagination.itemsPerPage;
        this.pagination.currentPage -= 1;

        if (this.pagination.rangeStart <= 0)
          this.pagination.rangeStart = 0;

        if (this.pagination.currentPage <= 1)
          this.pagination.currentPage = 1;
      },
      filteredSupport: function () {
        return this.support.data
          .filter(support =>
            contains(this.selected.type, support.type)
          );
      }
    }
  });
}
